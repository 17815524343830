<template>
  <Page>
    <ViewTitle :title="title" />
    <div class="container vcs-box p-2 mb-4">
      <div class="row">
        <div class="col-md-6">
          <div class="vcs-title">{{ labels.PersonalData }}</div>
          <div class="container">
            <div class="row" v-if="setPerson.TypeName == 'NaturalPerson'">
              <div class="col-md-6">
                <div class="row align-items-center">
                  <div class="col-6 vcs-name">{{ labels.Salutation }}</div>
                  <div class="col-6 vcs-value">
                    {{
                      setPerson.Salutation == 0
                        ? ""
                        : SalutationObj[setPerson.Salutation]
                    }}
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-6 vcs-name">{{ labels.FirstName }}</div>
                  <div class="col-6 vcs-value">{{ setPerson.FirstName }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row align-items-center">
                  <div class="col-6 vcs-name">{{ labels.DateOfBirth }}</div>
                  <div
                    v-if="
                      get_formatted_short_date(setPerson.DateOfBirth) ==
                      get_formatted_short_date('12/31/9999 11:59:59 PM')
                    "
                    class="col-6 vcf-input datepicker-input"
                  >
                    <!-- <label for="setting-datepicker" class="d-none">TT.MM.JJJJ</label> -->
                    <b-form-datepicker
                      :start-weekday="1"
                      :show-decade-nav="true"
                      :hide-header="true"
                      :max="maxDate"
                      @input="dateChange"
                      placeholder="TT.MM.JJJJ"
                      v-bind="calLabels[locale] || {}"
                      v-model="dob"
                      class="vcs-date-box"
                      size="sm"
                      :locale="locale"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                    >
                    </b-form-datepicker>
                    <!-- <input type="text" value="" placeholder="" name="setting-datepicker" id="setting-datepicker" class="d-none"> -->
                  </div>
                  <div v-else class="col-6 vcs-value">
                    {{ get_formatted_short_date(setPerson.DateOfBirth) }}
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-6 vcs-name">{{ labels.LastName }}</div>
                  <div class="col-6 vcs-value">{{ setPerson.LastName }}</div>
                </div>
              </div>
            </div>
            <div class="row" v-if="setPerson.TypeName == 'LegalPerson'">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-6 vcs-name">{{ labels.UserName }}</div>
                  <div class="col-6 vcs-value">{{ setPerson.Name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 setting-section-two">
          <div class="vcs-title">{{ labels.LocationData }}</div>
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="row align-items-center">
                  <div class="col-6 vcs-name">{{ labels.Street }}</div>
                  <div class="col-6 vcs-value">{{ mainAddress.Street }}</div>
                </div>
                <div class="row align-items-center">
                  <div class="col-6 vcs-name">{{ labels.StreetNumber }}</div>
                  <div class="col-6 vcs-value">
                    {{ mainAddress.StreetNumber }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row align-items-center">
                  <div class="col-6 vcs-name">
                    {{ labels.PostalCode }} und {{ labels.City }}
                  </div>
                  <div class="col-6 vcs-value" v-if="mainAddress.PostalCode">
                    {{ mainAddress.PostalCode }} {{ mainAddress.City }}
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-6 vcs-name">{{ labels.Country }}</div>
                  <div class="col-6 vcs-value">
                    {{ countryObj[mainAddress.Country] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   <div class="container pr-0 text-right mb-4">
  <router-link :to="{ name: 'userdata' }" class="settinglink rounded" v-if="!NotFullyRegisteredUser">
    {{ labels.ChangeUserData }}
  </router-link>
  <router-link :to="{ name: 'userdata' }" class="settinglink rounded" v-else>
    {{ labels.SetUserData }}
  </router-link>
</div>
    <SettingsForm :dob="dob"></SettingsForm>
  </Page>
</template>

<script>
import Page from "@/structure/Page.vue";
import ViewTitle from "@/components/ViewTitle.vue";
import SettingsForm from "@/forms/SettingsForm.vue";
import { vcm } from "@/mixins/vcm";
import { vvm } from "@/mixins/vvm";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  mixins: [vcm, vvm],
  name: "Settings",
  components: {
    Page,
    ViewTitle,
    SettingsForm,
  },
  data() {
    return {
      locale: "de",
      maxDate: new Date(),
      locales: [{ value: "de", text: "German (de)" }],
      calLabels: {
        de: {
          labelPrevDecade: "Vorheriges Jahrzehnt",
          labelPrevYear: "Vorheriges Jahr",
          labelPrevMonth: "Vorheriger Monat",
          labelCurrentMonth: "Aktueller Monat",
          labelNextMonth: "Nächster Monat",
          labelNextYear: "Nächstes Jahr",
          labelNextDecade: "Nächstes Jahrzehnt",
          labelToday: "Heute",
          labelSelected: "Ausgewähltes Datum",
          labelNoDateSelected: "Kein Datum gewählt",
          labelCalendar: "Kalender",
          labelNav: "Kalendernavigation",
          labelHelp: "Mit den Pfeiltasten durch den Kalender navigieren",
        },
      },
      dob: null,
      isShow: true,
      labels: {
        PersonalData: this.get_scope_translation("PersonalData", "label"),
        LocationData: this.get_scope_translation("LocationData", "label"),
        FirstName: this.get_scope_translation("FirstName", "label"),
        Nutzername: this.get_scope_translation("FirstName", "label"),
        LastName: this.get_scope_translation("LastName", "label"),
        DateOfBirth: this.get_scope_translation("DateOfBirth", "label"),
        Gender: this.get_scope_translation("Gender", "label"),
        Street: this.get_scope_translation("Street", "label"),
        StreetNumber: this.get_scope_translation("StreetNumber", "label"),
        ApartmentNumber: this.get_scope_translation("ApartmentNumber", "label"),
        PostalCode: this.get_scope_translation("PostalCode", "label"),
        City: this.get_scope_translation("City", "label"),
        Country: this.get_scope_translation("Country", "label"),
        Salutation: this.get_scope_translation("Salutation", "label"),
        SetUserData:this.get_scope_translation("SetUserData", "label"),
        ChangeUserData:this.get_scope_translation("ChangeUserData", "label"),
      },
      title: "",
    };
  },
  async beforeCreate() {
    try {
      const Url = process.env.VUE_APP_BASE_URL + "/v1/pages/usersetting";
      const response = await axios.get(Url, {
        params: { language: `${this.$root.config.localization.language}` },
      });
      if (response.data.responseCode === "200" && response.data.response) {
        this.title = response.data.response.Title;
      }
    } catch (e) {
      console.log("Error : " + e);
    }
  },
  created() {
    this.getUser();
  },
  mounted() {
    const FiledLabels = this.$root.get_label_fields(
      `${this.$root.config.localization.language}`,
      "Settings"
    );
    Object.assign(this.labels, FiledLabels);
    setTimeout(() => this.addAriaLabel(), 1100);
  },
  computed: {
    setPerson() {
      return this.$store.state.setting.person;
    },
    mainAddress() {
      return this.$store.state.setting.MainAddress;
    },
    countryObj() {
      return this.$store.getters["setting/countryObj"];
    },
    SalutationObj() {
      return this.$store.state.setting.Salutation;
    },
    NotFullyRegisteredUser() {
    return this.$store.state.setting.NotFullyRegisteredUser;
  },
  }, 
  methods: {
    ...mapActions({
      getUser: "setting/getUserAction",
    }),
    dateChange() {
      return this.dob;
    },
    addAriaLabel: function () {
      document.getElementsByTagName("button").forEach((v) => {
        if (!v.ariaLabel) {
          v.setAttribute("aria-label", "Auswählen");
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/_var.scss";
.settinglink{
    text-align: right;
    border: 2px solid black;
    padding: 5px 9px;
    text-decoration: none;
    font-weight: bolder;
    font-size: 1.125rem;
    display: inline-block;
  
}
.settinglink:hover{
  color:black;
   transition: color 0.15s ease-in-out, color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
caption h2 {
  color: $appFontColor;
}

thead {
  background-color: #e5eaf0;
}
.table-hover tbody tr:hover {
  color: #121212;
  background-color: #eff5f9;
}

thead tr th {
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: #000000;
  font-weight: 700;
}
tbody tr td {
  font-size: 0.938rem;
  line-height: 1.25rem;
  color: #000000;
}

.vcs-box .row .col-md-6 .vcs-title,
.container .row .vcs-title {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
  padding: 0 0 0.25rem 0.75rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #ffffff;
}
.vcs-name {
  font-size: 0.875rem;
  line-height: 1.188rem;
  font-weight: 700;
  padding: 0.25rem 0 0.25rem 0.75rem;
}
.vcs-value {
  font-size: 14px;
  line-height: 1.188rem;
  word-break: break-word;
  font-weight: normal;
}

.vcs-box {
  border: 1px solid #e5e5e5;
  background-color: #ebf0f4;
}

.datepicker-input {
  padding-right: 0px;
  padding-left: 0;
}

//media query
@media (max-width: 767px) {
  .setting-section-two {
    margin-top: 20px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .vcs-name {
    font-size: 12px;
    padding: 0.25rem 0 0 0;
  }
  .vcs-value {
    font-size: 12px;
    word-break: unset;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .vcs-name {
    font-size: 12px;
  }
  .vcs-value {
    font-size: 12px;
    word-break: unset;
  }
}
</style>

