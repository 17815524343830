<template>
  <b-form @submit.prevent="fm_submit">
    <div class="container">
      <div class="row"><div class="col span-2 vcs-title mt-2">{{ labels.ContactData }}</div></div>
      <div class="row">
        <div class="col-md-6">
          <label class="" for="fm_phonenumber">{{ labels.PhoneNumber }}</label>
          <b-form-input
            type="tel"
            id="fm_phonenumber"
            maxlength="16"
            minlength="10"
            v-model="PersonalPhone"
            :placeholder="labels.PhoneNumber"
            @focus.native="$v.PersonalPhone.$touch()"
            :aria-label="labels.PhoneNumber"
            class="mb-3 rounded vgs-form-input"
            :class="{ 'is-invalid': $v.PersonalPhone.$error }"
          >
          </b-form-input>
          <div
            v-if="$v.PersonalPhone.$error"
            class="invalid-feedback error-class"
          >
            <span
              v-if="!$v.PersonalPhone.phoneNumberCheck"
              class="float-left mb-2 mt-n2"
              >{{ labels.IncorrectPhoneNumber }}</span
            >
          </div>
        </div>

        <div class="col-md-6">
          <label class="" for="fm_email">{{ labels.EMailAddress }}</label>

          <b-form-input
            type="text"
            id="fm_email"
            v-model="userAddress.Email"
            :disabled="isReady"
            :placeholder="labels.EMailAddress"
            :aria-label="labels.EMailAddress"
            class="mb-3 rounded vgs-form-input"
          ></b-form-input>
        </div>
      </div>
      <div class="row">
        <div class="col span-2 vcs-title mt-2">{{ labels.PaymentData }}</div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="" for="fm_accountowner">{{
            labels.AccountOwner
          }}</label>
          <b-form-input
            type="text"
            id="fm_accountowner"
            v-model="mainBankAccount.Owner"
            :placeholder="this.labels.AccountOwner"
            :aria-label="this.labels.AccountOwner"
            class="mb-3 rounded vgs-form-input"
          ></b-form-input>
        </div>
        <div class="col-md-6">
          <label class="" for="fm_iban">{{ labels.IBAN }}</label>
          <b-form-input
            type="text"
            id="fm_iban"
            v-model.trim="mainBankAccount.IBAN"
            @focus.native="$v.mainBankAccount.IBAN.$touch()"
            :placeholder="this.labels.IBAN"
            :aria-label="this.labels.IBAN"
            :class="{ 'is-invalid': $v.mainBankAccount.IBAN.$error }"
            class="mb-3 rounded vgs-form-input"
          >
          </b-form-input>
          <div
            v-if="$v.mainBankAccount.IBAN.$error"
            class="invalid-feedback error-class"
          >
            <span
              v-if="!$v.mainBankAccount.IBAN.ibanCheck"
              class="float-left mb-2 mt-n2"
              >{{ labels.IncorrectIBANNumber }}</span
            >
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col span-2 vcs-title mt-2">{{ labels.DeliveryData }}</div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="" for="fm_street">{{ labels.Street }}</label>
          <b-form-input
            type="text"
            id="fm_street"
            v-model="userAddress.Street"
            :placeholder="this.labels.Street"
            :aria-label="this.labels.Street"
            class="mb-3 rounded vgs-form-input"
          ></b-form-input>
        </div>
        <div class="col-md-6">
          <label class="" for="fm_streetnumber">{{
            labels.StreetNumber
          }}</label>
          <b-form-input
            type="text"
            id="fm_streetnumber"
            v-model="userAddress.StreetNumber"
            :placeholder="this.labels.StreetNumber"
            :aria-label="this.labels.StreetNumber"
            class="mb-3 rounded vgs-form-input"
          ></b-form-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 col-5">
          <label class="" for="fm_postalcode">{{ labels.PostalCode }}</label>
          <b-form-input
            type="number"
            id="fm_postalcode"
            @keydown="checkLength"
            v-model="userAddress.PostalCode"
            :placeholder="labels.PostalCode"
            :aria-label="labels.PostalCode"
            class="mb-3 rounded vgs-form-input"
          ></b-form-input>
        </div>
        <div class="col-md-4 col-7">
          <label class="" for="fm_city">{{ labels.City }}</label>
          <b-form-input
            type="text"
            id="fm_city"
            v-model="userAddress.City"
            :placeholder="this.labels.City"
            :aria-label="this.labels.City"
            class="mb-3 rounded vgs-form-input"
          ></b-form-input>
        </div>
        <div class="col-md-6">
          <label class="" for="fm_municipality">{{ labels.Country }}</label>
          <b-form-select
            id="fm_municipality"
            :aria-label="labels.Country"
            v-model="userAddress.Country"
            :options="CountryList"
          >
          </b-form-select>
        </div>
      </div> -->
      <div class="row mt-4">
        <div class="col-md-12 col-sm-12 col-lg-6">
          <b-form-checkbox
            id="GetMessages"
            v-model="MessageSetting.GetMessages"
            name="GetMessages"
          >
            {{ labels.GetMessages }}
          </b-form-checkbox>
        </div>
      </div>
      <div class="row" >
        <div class="col-md-12 col-sm-12 col-lg-6">
          <b-form-checkbox
            id="GetNotification"
            v-model="MessageSetting.GetNotification"
            name="GetNotification"
          >
            {{ labels.GetNotification }}
          </b-form-checkbox>
        </div>
      </div>

      <!-- <div class="row ">
        <div class="col-md-12 col-sm-12 col-lg-6">
          <b-form-checkbox
            id="MessageSetting.ActiveAlternateEmail"
            v-model="mailShow"
            name="MessageSetting.ActiveAlternateEmail"
            >{{ labels.GetAlternateEmail }}</b-form-checkbox
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12 leftspace" v-if="mailShow">
          <label class="" for="diffAlternateMail">{{
            labels.AlternateEmail
          }}</label>
          <b-form-input
            type="text"
            id="diffAlternateMail"
            @focus.native="$v.diffAlternateMail.$touch()"
            v-model="diffAlternateMail"
            :placeholder="labels.AlternateEmail"
            :aria-label="labels.AlternateEmail"
            :class="{ 'is-invalid': $v.diffAlternateMail.$error }"
            class="mb-2 rounded vgs-form-input Alt-input"
          ></b-form-input>
          <div
            v-if="$v.diffAlternateMail.$error"
            class="invalid-feedback error-class"
          >
            <span
              v-if="!$v.diffAlternateMail.emailCheck"
              class="float-left mb-2 mt-n2"
              >{{ labels.IncorrectEmailAddress }}</span
            >

            <span v-if="diffrentEmailError" class="float-left mb-2 mt-n2">{{
              labels.IncorrectEmailAddress
            }}</span>
            <span v-if="!$v.diffAlternateMail.required">{{
              labels.EmailRequired
            }}</span>
            <span
              v-if="
                !$v.diffAlternateMail.email && $v.diffAlternateMail.required
              "
              >{{ labels.InvalidEmail }}</span
            >
          </div>
        </div>
      </div> -->
    </div>

    <div class="container mt-4">
      <div class="row justify-content-end">
        <div class="col-md-4">
          <b-button
            type="submit"
            :disabled="isdisabled"
            variant="primary"
            class="vcs-btn user-setting-btn p-2 rounded vgs-form-button"
            >{{ labels.Save }}</b-button
          >
        </div>
      </div>
      <div
        class="vgs-error-messages user-update-success-msg"
        v-html="get_error_messages"
      ></div>
    </div>
  </b-form>
</template>

<script>
import { vcm } from "@/mixins/vcm";
import { mapActions } from "vuex";
import { email } from "vuelidate/lib/validators";
export default {
  mixins: [vcm],
  props: {
    dob: {
      type: String,
    },
  },
  name: "SettingsForm",
  data() {
    return {
      mode: "alert",

      mailShow:
        localStorage.getItem("getalternate") ||
        !localStorage.getItem("alternate"),
      diffAlternateMail: localStorage.getItem("alternate") || "",
      diffrentEmailError: false,
      accountTypeName: null,
      isdisabled: false,
      isReady: true,
      PersonalPhoneArr: [],
      labels: {
        EMailAddress: this.get_scope_translation("EMailAddress", "label"),
        Save: this.get_scope_translation("Save", "label"),
        PhoneNumber: this.get_scope_translation("PhoneNumber", "label"),
        ContactData: this.get_scope_translation("ContactData", "label"),
        PaymentData: this.get_scope_translation("PaymentData", "label"),
        DeliveryData: this.get_scope_translation("DeliveryData", "label"),
        AccountOwner: this.get_scope_translation("AccountOwner", "label"),
        IBAN: this.get_scope_translation("IBAN", "label"),
        Street: this.get_scope_translation("Street", "label"),
        StreetNumber: this.get_scope_translation("StreetNumber", "label"),
        PostalCode: this.get_scope_translation("PostalCode", "label"),
        City: this.get_scope_translation("City", "label"),
        Country: this.get_scope_translation("Country", "label"),
        EmailRequired: this.get_scope_translation("EmailRequired", "label"),
        InvalidEmail: this.get_scope_translation("InvalidEmail", "label"),
        AlternateEmail: this.get_scope_translation("AlternateEmail", "label"),
        GetMessages: this.get_scope_translation("GetMessages", "label"),
        GetNotification: this.get_scope_translation("GetNotification", "label"),
        GetAlternateEmail: this.get_scope_translation(
          "GetAlternateEmail",
          "label"
        ),

        Invalidpostalcode: "Postleitzahl ist ungültig",
        IncorrectPhoneNumber: "Ungültige Telefonnummer",
        IncorrectIBANNumber: "Ungültige Telefonnummer",
        IncorrectEmailAddress: this.get_scope_translation(
          "IncorrectEmailAddress",

          "label"
        ),
        SuccessUpdateProfile: this.get_translation(
          "Error",
          "UpdateProfileSuccess",
          "label"
        ),
        ActivateAlternateEmailSuccess: this.get_scope_translation(
          "ActivateAlternateEmailSuccess",

          "label"
        ),
      },
      userAddress: {},
      mainBankAccount: {},
      email: null,
      PersonalPhone: null,
      submitted: false,
      phoneNumberUUID: null,
    };
  },
  validations: {
    PersonalPhone: {
      phoneNumberCheck: function (value) {
        if (value) {
          let phoneNumberRegex = /^\+(?:[0-9]⋅?){6,14}[0-9]$/;
          return value && phoneNumberRegex.test(value);
        } else {
          return true;
        }
      },
    },
    diffAlternateMail: {
      required: function (value) {
        if (this.mailShow && !value) {
          return false;
        }
        return true;
      },
      email,
      emailCheck: function (value) {
        if (!value) {
          return true;
        } else if (
          value.toLowerCase() === this.userAddress.Email.toLowerCase()
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
    mainBankAccount: {
      IBAN: {
        ibanCheck: function (value) {
          if (value) {
            let ibanRegex =
              /[A-Z]{2}\d{2} ?\d{4} ?\d{4} ?\d{4} ?\d{4} ?[\d]{0,2}/;
            return value && ibanRegex.test(value);
          } else {
            return true;
          }
        },
      },
    },
  },
  computed: {
    person() {
      return { ...this.$store.state.setting.person };
    },
    MainBankAccount() {
      return { ...this.$store.state.setting.MainBankAccount };
    },
    Address() {
      return { ...this.$store.state.setting.MainAddress };
    },
    UserInformation() {
      return { ...this.$store.state.setting.UserInformation };
    },
    CountryList() {
      return this.$store.getters["setting/getCountryList"];
    },
    MessageSetting() {
      return { ...this.$store.state.setting.MessageSetting };
    },
     UserSignIn() {
      return { ...this.$store.state.setting.UserSignIn };
    },
  },
  mounted() {
    this.accountTypeName = this.person.TypeName;
    const FiledLabels = this.$root.get_label_fields(
      `${this.$root.config.localization.language}`,
      "SettingsForm"
    );
    Object.assign(this.labels, FiledLabels);
    const fieldLabelData = localStorage.getItem(
      "form_field_labels_" +
        this.$root.config.localization.language +
        "_SignInForm"
    );
    const FilledLabels = JSON.parse(fieldLabelData);
    for (var i = 0; i < FilledLabels.Label.length; i++) {
      if (FilledLabels.Label[i].Name == "EmailRequired") {
        this.labels.EmailRequired = FilledLabels.Label[i].Label;
      }
    }
    // Retrieve values from local storage, if available

    this.diffAlternateMail =
      localStorage.getItem("alternate") || this.MessageSetting.AlternateEMail;
    this.mailShow =
      localStorage.getItem("getalternate") === "true" ||
      this.MessageSetting.ActiveAlternateEmail;
  },
  watch: {
    mailShow: function (newVal) {
      if (!newVal) {
        this.diffAlternateMail = null; // Clear the differentAlternate field when mailShow is false
      }
    },
    Address: function () {
      this.userAddress = JSON.parse(
        JSON.stringify(this.$store.state.setting.Address)
      );
      // let personEmail = this.$store.state.setting.UserInformation.filter(
      //   (obj) => obj.TypeName === "PersonalEMail"
      // );
      this.PersonalPhoneArr = this.$store.state.setting.UserInformation.filter(
        (obj) => obj.TypeName === "PersonalPhone"
      );
      this.mainBankAccount = JSON.parse(
        JSON.stringify(this.$store.state.setting.MainBankAccount)
      );
     this.userAddress.Email = this.$store.state.setting.UserSignIn.Email;
      this.PersonalPhone =
        this.PersonalPhoneArr[0] && this.PersonalPhoneArr[0].Value;
      this.phoneNumberUUID = !this.PersonalPhoneArr.length
        ? this.person.Uuid
        : this.PersonalPhoneArr[0].UUID;
    },
  },
  methods: {
    ...mapActions({
      getUser: "setting/getUserAction",
    }),

    checkLength($event) {
      if (
        this.userAddress.PostalCode.length > 3 &&
        $event.keyCode !== 8 &&
        $event.keyCode !== 9
      )
        $event.preventDefault();
    },
    fm_submit(evt) {
      evt.preventDefault();
      this.$v.$touch();

      if (
        (this.$v.PersonalPhone && this.$v.PersonalPhone.$invalid) ||
        (this.$v.mainBankAccount && this.$v.mainBankAccount.$invalid) ||
        (this.$v.userAddress && this.$v.userAddress.$invalid) ||
        (this.$v.diffAlternateMail && this.$v.diffAlternateMail.$invalid)
      ) {
        return;
      }

      this.submitted = true;
      this.isdisabled = true;

      let data = {
        UserInformation: [
          {
            UUID: this.phoneNumberUUID,
            TypeName: "PersonalPhone",
            Value: this.PersonalPhone,
          },
        ],
        MainBankAccount: {
          UUID: this.MainBankAccount.UUID,
          TypeName: this.MainBankAccount.TypeName,
          Owner: this.mainBankAccount.Owner,
          BankName: this.MainBankAccount.BankName,
          BankCode: this.MainBankAccount.BankCode,
          IBAN: this.mainBankAccount.IBAN,
          BIC: this.MainBankAccount.BIC,
        },

        MainAddress: {
          TypeName: this.Address.TypeName,
          Street: this.userAddress.Street ? this.userAddress.Street : "",
          StreetNumber: this.userAddress.StreetNumber
            ? this.userAddress.StreetNumber
            : "",
          Staircase: this.userAddress.Staircase
            ? this.userAddress.Staircase
            : "",
          ApartmentNumber: this.userAddress.ApartmentNumber
            ? this.userAddress.ApartmentNumber
            : "",
          PostalCode: this.userAddress.PostalCode
            ? this.userAddress.PostalCode
            : "",
          City: this.userAddress.City ? this.userAddress.City : "",
          Country: this.userAddress.Country
            ? parseInt(this.userAddress.Country)
            : 1,
          Phone: this.userAddress.Phone,
        },
        MessageSetting: {
          GetMessages: this.MessageSetting.GetMessages,
          GetNotification: this.MessageSetting.GetNotification,
          AlternateEMail: this.diffAlternateMail,
          ActiveAlternateEmail: this.mailShow,
        },
        DateOfBirth: this.dob,
        Name:this.person.Name,
        FirstName: this.person.FirstName,
        LastName: this.person.LastName,
        Middlename: "",
        Salutation:  this.person.Salutation ? parseInt(this.person.Salutation) : 0,
        SourceUUID: this.person.SourceUUID ? this.person.SourceUUID : "00000000-0000-0000-0000-000000000000",
        AcademicTitles: this.person.AcademicTitles,
        PostNominalTitles: this.person.PostNominalTitles,
        UserSignIn: {
          EMail: this.userAddress.Email,
          Password: this.password,
          StateName: this.$store.state.setting.NotFullyRegisteredUser? "Created" : this.UserSignIn.StateName,
          TypeName: this.$store.state.setting.NotFullyRegisteredUser? "User" : this.UserSignIn.TypeName,
        },
      };

      this.$root.data_access.setIdentifier("user.setting.updated");
      this.$root.data_access.setReceiver("component", this);
      this.$root.data_access.addPathParameter(
        "user-uuid",
        this.$root.get_user_info("Uuid", this.person.Uuid)
      );
      this.$root.data_access.setRequestBody(data);
      this.$root.data_access.call("user_settings");
    },

    receive(dataObject) {
      if (dataObject) {
        switch (dataObject.identifier) {
          case "user.setting.updated":
            if (
              dataObject.data.responseCode === "200" &&
              dataObject.data.responseMessage === "Ok"
            ) {
              this.$root.add_alert(
                "Erfolg",
                this.labels.SuccessUpdateProfile,
                "check2-circle"
              );
              this.isdisabled = false;
              localStorage.setItem("getalternate", this.mailShow);
              localStorage.setItem("alternate", this.diffAlternateMail);
              this.getUser();
            } else if (dataObject.data.responseCode === "409") {
              this.diffrentEmailError = true;
            }

            break;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/_var.scss";
.custom-control-input:checked ~ .custom-control-label:before {
  border-color: #ffde00 !important;
  background-color: #ffde00 !important;
}
.custom-select {
  z-index: 0;
}
.vcs-title1 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
  padding: 0;
}
.vcs-btn {
  width: 100%;
}
.leftspace{
  margin-left:29px;
}
.Alt-input{
  width:94%;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.user-update-success-msg ul {
  list-style-type: none;
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.custom-control{
  z-index: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1100px) {
  .custom-control-label:before, .custom-control-label:after {
    top: 4px;
  }

}
@media only screen and (max-width: 568px) {
  .Alt-input{
  width:100%;
}

}
/* Firefox */
// input[type="number"] {
//   -moz-appearance: textfield;
// }
</style>
